<template>
  <div
    class="shop-products-mix"
    :class="{
      'is-recommendation': data?.is_recommendation,
      'is-sticky': data.is_sticky,
      'lift-index': selectFieldOpen,
      'is-invisible': !componentVisible,
    }"
  >
    <div class="shop-products-mix__grid">
      <div
        v-if="(computedSpeaker || data.title || data.pretitle)"
        class="shop-products-mix__head-wrapper"
      >
        <div class="shop-products-mix__head">
          <div
            v-if="computedSpeaker && data.is_recommendation"
            class="shop-products-mix__head-speaker-image"
          >
            <AtomImage
              v-if="computedSpeaker?.image?.url"
              :src="computedSpeaker?.image?.url"
            />
          </div>
          <div>
            <div class="shop-products-mix__pretitle">
              <span v-if="data.show_count">{{ total }}</span>
              {{ data.pretitle }}
              <span v-if="computedSpeaker?.image && data.is_recommendation">
                {{ useTranslation('general', 'by_speaker') }}
              </span>

              <SlotLink
                v-if="computedSpeaker?.image && data.is_recommendation"
                :url="computedSpeaker?.sb_slug"
                class="shop-products-mix__head-speaker-name"
              >
                {{ computedSpeaker?.name }}
              </SlotLink>
            </div>
            <div class="shop-products-mix__title">
              {{ data.title }}
            </div>
          </div>
        </div>

        <FilterMain
          v-if="mainFilter && !data.is_curated"
          v-bind="mainFilter"
          :counts="products?.meta?.mainFilterCounts"
          @set-active-main-filter-name="setActiveMainFilterName($event)"
          @set-filter-types="setFilterTypes($event)"
        />
      </div>

      <FilterSecondary
        v-if="data?.secondary_filter.length === 1 && !data.is_curated"
        :data="data.secondary_filter[0]"
        :prefill-value="data.secondary_filter[0].prefill_value"
        :is-hidden="data.hide_secondary_filter"
        :tabs="secondaryFilterItems"
        :preselected-items="preslectedFilterItems"
        @set-filter-relationals="setRelationalByIds"
      />

      <div class="shop-products-mix__sticky-container">
        <FilterText
          v-if="!useGlobalAdvancedFilter && data?.text_filter.length === 1 && !data.is_curated"
          :data="data.text_filter[0]"
          :is-hidden="data.hide_text_filter"
          :is-general="false"
          @set-text-search="setFilterTextSearch"
        />

        <ClientOnly>
          <Teleport
            v-if="data?.advanced_filter?.length === 1 && !data.is_curated && renderAdvancedFilter"
            to=".shop-products-combined__filter-advanced-teleport"
            :disabled="!useGlobalAdvancedFilter"
          >
            <FilterAdvanced
              :data="data.advanced_filter[0]"
              :is-hidden="data.hide_advanced_filter"
              :dropdowns="advancedFilterDropdowns"
              @set-filter-advanced="setFilterAdvanced"
              @set-availability="setAvailability"
            />
          </Teleport>
        </ClientOnly>
        <div class="shop-products-mix-sticky__background" />
      </div>

      <ShopProductRenderer
        v-if="containerVisible"
        :products="productsToRender"
      />

      <div v-else-if="!isPendingDefault" class="shop-products-mix____no-products">
        {{ useTranslation('general', 'no_products') }}
      </div>

      <div
        v-if="data.show_load_more"
        class="shop-products-mix__btn-load-more"
      >
        <AtomLoadMore
          v-if="(products?.meta?.mainFilterCounts || curatedCount) && props.data?.show_load_more"
          :button-text="loadMoreText"
          :max-item-amount="curatedCount > 0
            ? curatedCount
            : products?.meta?.mainFilterCounts[activeMainFilterName] || 0"
          :current-item-amount="productsToRender?.length"
          :is-loading="false"
          @load-more="loadMore()"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
    data: {
        type: Object,
        default: () => {},
    },
    useGlobalAdvancedFilter: {
        type: Boolean,
        default: false,
    },
    globalFilterData: {
        type: Object,
        default: () => {},
    },
    renderAdvancedFilter: {
        type: Boolean,
        default: true,
    },
    passedInSpeaker: {
        type: Object,
        default: null,
    },
    isCombined: {
        type: Boolean,
        default: false,
    },
});

/*
    Dependencies
*/
const context = useContextStore();
const strapiClient = useStrapiClient();
const { getRecommendedSpeakerById } = useRecommendedSpeaker();
const emit = defineEmits(['set-filter-advanced', 'set-availability']);
const { sendEvent } = useGTM();

const selectFieldPopupStore = useSelectFieldPopupStore();
const isPopupDisabled = computed(() => props.data.disable_product_popup);
provide('is-popup-disabled', isPopupDisabled);
const selectFieldOpen = computed(() => selectFieldPopupStore.isOpen);

// Total count
const total = ref(0);

/*
    Products query
*/
const pageSize = computed(() => parseInt(props.data.pagination_size, 10));

const rawQuery = reactive({
    page: 1,
    pageSize: pageSize.value || 6,
    onlyRecommended: props.data.is_recommendation,
    countGroups: [],
    types: [
        'product',
    ],
    relationalByIds: {},
    availability: 'Alle',
    relationalByText: {
        searchString: '',
        additional_relations: [],
    },
    relationalByName: {},
    initialCollection: null,
    initialId: null,
});

/*
    Get recommended speaker
*/
const speakerId = context.getSpeakerId?.length > 0 ? context.getSpeakerId[0] : 2;
const speaker = props.data.is_recommendation && !props.useGlobalAdvancedFilter
    ? getRecommendedSpeakerById(speakerId)
    : null;
const computedSpeaker = computed(() => props.passedInSpeaker?.value || speaker?.value || null);

/*
    Initial Context
*/
const collection = context.getKeyByStrapiDataKey('strapi_collection');
const id = context.getKeyByStrapiDataKey('strapi_id');

rawQuery.initialCollection = collection;
rawQuery.initialId = id;
rawQuery.allFacets = !!props.useGlobalAdvancedFilter;

/*
    Relational Groups
*/
if (Array.isArray(props?.data?.relation_groups) && props?.data?.relation_groups.length > 0) {
    props.data?.relation_groups?.forEach((group) => {
        const { relation_path: relationPath, type } = group;

        const mappedPath = relationPath?.map((path) => path.relation_key);
        const contextKey = `strapi_${pluralToSingular(mappedPath)}_id`;
        const rawItems = context.getKeyByStrapiDataKey(contextKey);

        rawQuery.relationalByIds[type] = rawItems;
    });
}

/*
    Text Filter
*/
if (Array.isArray(props?.data?.text_filter) && props?.data?.text_filter.length > 0) {
    const textFilter = { ...props.data.text_filter[0] };
    const flattened = textFilter?.additional_relational_search?.map(
        (item) => item,
    );

    rawQuery.relationalByText.additional_relations = flattened;

    if (textFilter?.prefill_value) {
        rawQuery.relationalByText.searchString = textFilter.prefill_value;
    }
}

/*
    Main Filter
*/
const activeMainFilterName = ref('');
if (
    Array.isArray(props?.data?.main_filter)
    && props?.data?.main_filter.length > 0
    && Array.isArray(props?.data?.main_filter[0].tabs)
    && props?.data?.main_filter[0].tabs.length > 0
) {
    // Set types from preselected tab
    const activeTab = props?.data?.main_filter[0].tabs?.find((tab) => tab.is_preselected === true);
    rawQuery.types = activeTab.types || ['Alle'];
    activeMainFilterName.value = activeTab.name || 'Alle';

    const mapped = props.data.main_filter[0].tabs?.map(
        (tab) => ({
            name: tab.name,
            types: tab.types?.map((flatten) => flatten),
        }),
    );

    mapped.forEach((item) => {
        rawQuery.countGroups[item.name] = item.types;
    });
} else {
    rawQuery.countGroups = {
        Produkte: ['product'],
    };
}

/*
    Secondary Filter
*/

const initialSecondaryFilters = ref({});
const allIndications = useGlobalKeyValueData('indication');
const allFocusTopics = useGlobalKeyValueData('focus-topic');

if (Array.isArray(props?.data?.secondary_filter) && props?.data?.secondary_filter.length > 0) {
    const advancedFilterSettings = { ...props.data.secondary_filter[0] };

    /*
        Preselected Focus Topics and Indications
    */
    if (advancedFilterSettings.preselected_focus_topics?.length > 0) {
        if (!initialSecondaryFilters.value['focus-topics']) {
            initialSecondaryFilters.value['focus-topics'] = [];
        }

        initialSecondaryFilters.value['focus-topic'] = toRaw(
            advancedFilterSettings.preselected_focus_topics,
        );
    }

    if (advancedFilterSettings.preselected_indications?.length > 0) {
        if (!initialSecondaryFilters.value.indications) {
            initialSecondaryFilters.value.indications = [];
        }

        initialSecondaryFilters.value.indications = toRaw(
            advancedFilterSettings.preselected_indications,
        );
    }
}

/*
    Dropdown Advanced Filter
*/
const advancedFilterPreselections = {};

if (Array.isArray(props?.data?.advanced_filter) && props?.data?.advanced_filter.length > 0) {
    const advancedFilterSettings = { ...props.data.advanced_filter[0] };

    /*
        @example output
        {
            indications: [1, 2, 3],
            focus_topics: [1, 2, 3],
        }
    */
    advancedFilterSettings.dropdowns?.forEach((dropdown) => {
        if (
            Array.isArray(dropdown.preselections)
            && dropdown.preselections.length > 0
            && !dropdown.is_availability
        ) {
            advancedFilterPreselections[dropdown.collection] = dropdown.preselections?.map(
                (item) => item.value,
            );
        }
    });

    rawQuery.relationalByName = advancedFilterPreselections;
}

/*
    Fetch products
*/
const computedQuery = computed(() => useQueryString(rawQuery));
const isMainFilterChanged = ref(false);
let mainFilterCounts = reactive({});
const items = ref([]);
const uid = getUid();
const {
    execute: executeDefault,
    data: products,
    error,
    pending: isPendingDefault,
} = useLazyAsyncData(
    `/shop-api/shop-items/get-items-by-filters/${uid}`,
    () => strapiClient(`shop-items/get-items-by-filters?${computedQuery.value}`, {
        method: 'GET',
    }),
    {
        immediate: false,
        transform: (res) => {
            if (!isMainFilterChanged.value) {
                mainFilterCounts = res.countsWithKey;
                isMainFilterChanged.value = false;
            }

            items.value = rawQuery.page === 1
                ? res.items
                : items.value.concat(res.items);

            if (rawQuery?.relationalByText?.searchString) {
                sendEvent({
                    event: 'view_search_results',
                    search_term: computedQuery.value,
                });
            }

            if (Array.isArray(items.value) && items.value.length > 0) {
                sendEvent({
                    event: 'view_item_list',
                    ecommerce: {
                        currency: 'CHF',
                        value: null,
                        items: items?.value?.map((product) => ({
                            id: product.id,
                            name: product.name,
                            pharma_code: product.pharma_code,
                            price: product.price,
                            is_sendable: product.is_sendable || false,
                            needs_prescription: product.needs_prescription || false,
                            short_text: product.short_text,
                            description: product.description,
                        })),
                    },
                });
            }

            total.value = res.totalCount;

            return {
                items: items.value,
                facets: res.facets,
                meta: {
                    total: res.totalCount,
                    mainFilterCounts,
                },
            };
        },
    },
);

watch(() => error.value, (newValue) => {
    if (newValue) {
        useSentryError(newValue);
    }
});

const isPrepared = computed(
    () => (!!(allIndications?.data?.value && allFocusTopics?.data?.value)),
    { immediate: true },
);

watch(() => isPrepared, (newValue) => {
    if (newValue) {
        executeDefault();
    }
}, { immediate: true });

/*
    Filter rendering
*/
// Main Filter
const mainFilter = computed(() => {
    const filter = props?.data?.main_filter[0];
    if (!filter) return null;

    return {
        tabs: filter.tabs,
        isHidden: props.data.hide_main_filter,
    };
});

// Secondary Filter
const secondaryFilterItems = ref([]);
const preslectedFilterItems = ref([]);
watchEffect(() => {
    const indications = products?.value?.facets?.indications || {};
    const mappedIndications = [];

    Object.entries(indications).forEach(([name, value]) => {
        if (!value || value === 0) return;
        mappedIndications.push({
            name,
            collection: 'indications',
            id: allIndications?.data?.value?.find(
                (item) => item.name === name,
            )?.value,
            amount: value,
            is_preselected: initialSecondaryFilters.value.indications?.includes(
                allIndications?.data?.value?.find(
                    (item) => item.name === name,
                )?.value,
            ),
        });
    });

    const mappedFocusTopics = [];
    const focusTopics = products?.value?.facets?.focus_topics || {};

    Object.entries(focusTopics).forEach(([name, value]) => {
        if (!value || value === 0) return;

        mappedFocusTopics.push({
            name,
            collection: 'focus_topics',
            id: allFocusTopics?.data?.value?.find(
                (item) => item.name === name,
            )?.value,
            amount: value,
            is_preselected: initialSecondaryFilters.value.focus_topics?.includes(
                allFocusTopics?.data?.value?.find(
                    (item) => item.name === name,
                )?.value || false,
            ),
        });
    });

    const mergedSecondaryFilters = [
        ...mappedIndications,
        ...mappedFocusTopics,
    ];

    const filteredSecondaryFilters = mergedSecondaryFilters.filter(
        (item) => item.amount > 0 && item.id,
    );

    const sortedSecondaryFilters = filteredSecondaryFilters.sort(
        (a, b) => a.name.localeCompare(b.name),
    );

    secondaryFilterItems.value = sortedSecondaryFilters;

    preslectedFilterItems.value = sortedSecondaryFilters.filter(
        (item) => item.is_preselected,
    );
});

// Advanced Filter
const isInitial = ref(true);
const isAvailabilityChanging = ref(false);

const availabilityCounts = ref({
    Alle: 0,
    'Click and Collect': 0,
    Versendbar: 0,
});

const setAvailabilityCounts = (values) => {
    availabilityCounts.value = {
        Alle: (values?.true || 0) + (values?.false || 0),
        'Click and Collect': values?.false || 0,
        Versendbar: values?.true || 0,
    };
};

const advancedFilterDropdowns = computed(() => {
    if (Array.isArray(props?.data?.advanced_filter) && props?.data?.advanced_filter.length > 0) {
        const advancedFilterSettings = { ...props.data.advanced_filter[0] };
        const facets = products?.value?.facets || {};
        const dropdownsToRender = [];

        // This is not triggerd when used in global filter
        // Needs investigation
        advancedFilterSettings.dropdowns.forEach((dropdown) => {
            const facet = facets[dropdown.collection] || {};
            let prefillValue = null;

            if (isInitial) {
                if (dropdown.is_availability) {
                    prefillValue = 'Alle';
                } else {
                    prefillValue = dropdown.preselections?.map((item) => item.value) || [];
                }
            }

            if (!isAvailabilityChanging.value) {
                setAvailabilityCounts(facets?.is_sendable || {});
            }

            const mappedOptions = dropdown.is_availability
                ? availabilityDrowdownOptions.value?.map((item) => ({
                    ...item,
                    count: availabilityCounts.value[item.label] || 0,
                }))
                : Object.entries(facet)?.map(([name, value]) => ({
                    key: dropdown.collection,
                    value: name,
                    label: name,
                    count: value,
                    _uid: getUid(),
                }))?.sort((a, b) => a.label.localeCompare(b.label)) || [];

            dropdownsToRender.push({
                ...dropdown,
                field_key: dropdown.collection || 'availability',
                is_multi_select: !dropdown.is_availability,
                ...prefillValue && { prefillValue },
                collection: dropdown.collection || 'availability',
                is_hidden: dropdown.is_availability
                    ? availabilityCounts.Alle === 0
                    : mappedOptions?.length === 0 || !mappedOptions || dropdown.is_hidden,
                options: mappedOptions,
            });
        });

        isInitial.value = false;
        return dropdownsToRender;
    }

    return [];
});

// const total = computed(() => products?.value?.meta?.total || 0);

/*
    Load More
*/
const loadMoreText = computed(() => (props.data.is_recommendation === true
    ? useTranslation('general', 'load_more_recommendations')
    : useTranslation('general', 'load_more')
));

/* ----------------- Curated Products ----------------- */
if (props.data.is_curated) {
    rawQuery.items = [];
    delete rawQuery.allFacets;

    props.data?.relation_groups?.forEach((group) => {
        const { relation_path: relationPath, type } = group;

        const mappedPath = relationPath?.map((path) => path.relation_key);
        const contextKey = `strapi_${pluralToSingular(mappedPath)}_id`;

        const rawItems = context.getKeyByStrapiDataKey(contextKey);

        /* Check by find if an item by type exists in raw query */
        const exists = rawQuery.items
            .find((item) => item.type === type && item.column === mappedPath[0]);

        if (!exists) {
            rawQuery.items.push({
                type,
                column: mappedPath[0],
                ids: [],
            });
        }

        const entry = rawQuery.items
            .find((item) => item.type === type && item.column === mappedPath[0]);
        if (Array.isArray(rawItems)) {
            entry.ids.push(...rawItems);
        }
    });

    /**
     * Cause in advanced filter relationalByName.brands
     * is merged collection of brands and manufacturers
    */
    if (rawQuery?.relationalByName?.brands) {
        rawQuery.filterByBrands = rawQuery?.relationalByName?.brands?.map(
            (item) => item,
        );
        rawQuery.filterByManufacturers = rawQuery?.relationalByName?.manufacturers?.map(
            (item) => item,
        );
    }
}

const curatedCount = ref(0);
const curatedItems = ref([]);
const {
    execute: executeCurated,
    error: curatedError,

} = useLazyAsyncData(
    `/shop-items/find-many/${uid}`,
    () => strapiClient('/shop-items/find-many', {
        method: 'POST',
        body: rawQuery,
    }),
    {
        immediate: false,
        transform: (res) => {
            const { count } = res;
            curatedCount.value = count;
            curatedItems.value = rawQuery.page === 1
                ? res?.items
                : curatedItems.value.concat(res.items);

            total.value = res.count;
            return curatedItems.value;
        },
    },
);

watch(() => curatedError.value, (newValue) => {
    if (newValue) {
        useSentryError(newValue);
    }
});

if (props.data.is_curated) {
    executeCurated();
}

const productsToRender = computed(() => (
    props.data.is_curated ? curatedItems.value : items.value
), { immediate: true });

/*
    Styling
*/
const isFilterVisible = computed(() => props?.data?.main_filter?.length === 1
      || props?.data?.secondary_filter?.length === 1
      || (!props?.useGlobalAdvancedFilter && props?.data?.advanced_filter.length === 1)
      || (!props?.useGlobalAdvancedFilter && props?.data?.text_filter.length === 1));

const paddingTop = computed(() => (isFilterVisible.value ? '50px' : '20px'));

const componentVisible = ref(true);
const containerVisible = ref(true);
watch(() => total.value, (newVal) => {
    containerVisible.value = newVal !== 0;

    if (newVal === 0) {
        componentVisible.value = !props.data.is_curated && !props.data.is_recommendation;
    } else {
        componentVisible.value = true;
    }
}, { immediate: true });

/* ----------------- Load More ----------------- */
const loadMore = () => {
    rawQuery.page += 1;

    if (props.data.is_curated) {
        executeCurated();
    } else {
        executeDefault();
    }
};

/* ----------------- Filter Functions ----------------- */
const resetCurrentPage = () => {
    rawQuery.page = 1;
    rawQuery.pageSize = pageSize.value;
};

// Main Filter -> Set types that should be fetched
const setFilterTypes = (data) => {
    resetCurrentPage();
    isMainFilterChanged.value = true;
    const flattened = data.values?.map((item) => item);
    rawQuery.types = flattened;
    executeDefault();
};

// Main Filter -> Set the name of the active filter to display
const setActiveMainFilterName = (data) => {
    isAvailabilityChanging.value = false;
    activeMainFilterName.value = data;
};

// Secondary Filter -> Set relational by ids
const setRelationalByIds = (data) => {
    resetCurrentPage();
    isAvailabilityChanging.value = false;
    rawQuery.relationalByIds = data.values;
    executeDefault();
};

// Text Filter -> Set text search
const setFilterTextSearch = (data) => {
    isAvailabilityChanging.value = false;
    const values = typeof data.values === 'string' ? data.values : '';
    rawQuery.relationalByText.searchString = values;
    resetCurrentPage();
    executeDefault();
};

// Advanced Filter -> Set relational by name
const setFilterAdvanced = async (data) => {
    isAvailabilityChanging.value = false;
    rawQuery.relationalByName = data.values;

    if (props.useGlobalAdvancedFilter) {
        emit('set-filter-advanced', data);
    } else {
        resetCurrentPage();
        executeDefault();
    }
};

// Advanced Filter -> Set availability
const setAvailability = (data) => {
    isAvailabilityChanging.value = true;
    rawQuery.availability = data.values;

    if (props.useGlobalAdvancedFilter) {
        emit('set-availability', data);
    } else {
        resetCurrentPage();
        executeDefault();
    }
};

/*
      Watcher for global advanced filter
  */
if (props.useGlobalAdvancedFilter) {
    watch(() => props.globalFilterData, (newValue) => {
        rawQuery.relationalByName = newValue.relationalByName;
        rawQuery.relationalByText.searchString = newValue.relationalByText.searchString;
        rawQuery.availability = newValue.availability;

        executeDefault();
    }, { deep: true });
}
</script>

<style lang="scss" scoped>
.shop-products-mix {
    @include grid-full();
    @include fluid('margin-bottom', 60px, 120px);

    &.is-invisible {
        display: none;
    }

    .shop-products-combined & {
        @include fluid('margin-bottom', 10px, 20px);
    }

    &.is-recommendation {
        background-color: $C_GREEN_BRIGHT;
        border-bottom-right-radius: 50px;
        border-top-left-radius: 50px;
    }

}

.shop-products-mix__grid {
    @include grid-layout();

    width: 100%;
    max-width: var(--grid-width);
    padding: v-bind(paddingTop) var(--page-padding) 50px;
    margin: 0 auto;
}

.shop-products-mix__head-author-name {
    color: $C_PRIMARY;
    transition: color 0.15s ease-in-out;

    &:hover {
        color: $C_SECONDARY;
    }
}

.shop-products-mix__sticky-container {
    @include grid-columns();

    .is-sticky & {
        position: sticky;
        z-index: 20;
        top: var(--headerHeight);
        bottom: 10px;
        padding-top: 40px;
        background: $C_WHITE;

        & + .shop-product-renderer {
            @include fluid('margin-top', 26px, 114px);
        }
    }

    .lift-index {
        z-index: 150;
    }

}

.shop-products-mix-sticky__background {
    .is-sticky & {
        @include grid-full;
        position: absolute;
        z-index: -1;
        top: 0;
        height: 100%;
        box-shadow: 0px 30px 30px rgba(0, 0, 0, 0.05);
        pointer-events: none;
    }
}

.shop-products-mix__head-wrapper {
    @include grid-columns();
    @include fluid('margin-bottom', 14px, 30px);

    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;

    @include tablet-portrait {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        row-gap: 21px;
    }
}

.shop-products-mix__head {
    position: relative;
    display: flex;
    min-width: 50%;
    align-items: center;
    color: $C_GREEN_DARK;
    gap: 20px;
}

.shop-products-mix__head-speaker-name {
    color: $C_PRIMARY;
}

.shop-products-mix__pretitle {
    @include typo-size('p');
    @include typo-font('light');

    margin-bottom: 6px;

    @include mobile {
        margin-bottom: 0;
    }
}

.shop-products-mix__title {
    @include typo-size('h2');
    @include typo-font('regular');
}

.shop-products-mix__product-wrapper {
    @include grid-layout();
    @include grid-columns(1, var(--grid-columns));
    @include fluid('margin-bottom', 20px, 40px);

    width: 100%;
    max-width: var(--grid-width);
    row-gap: var(--grid-gutter);
}

.shop-products-mix__btn-load-more {
    @include grid-columns(1, var(--grid-columns));

    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.shop-products-mix____no-products {
    @include grid-columns(1, var(--grid-columns));
    @include typo-size('p');
    @include typo-font('light');

    color: $C_GREEN_DARK;
    text-align: center;
}
</style>
