/*
    States
*/
const isFetched = [];
export const availabilityDrowdownOptions = ref([
    {
        collection: 'availability',
        value: 'Alle',
        label: 'Alle',
        count: 1,
        prefillValue: [],
        _uid: getUid(),
    },
    {
        collection: 'availability',
        value: 'Click and Collect',
        label: 'Click and Collect',
        status: 'false',
        count: 1,
        prefillValue: [],
        _uid: getUid(),
    },
    {
        collection: 'availability',
        value: 'Versendbar',
        label: 'Versendbar',
        status: 'true',
        count: 1,
        prefillValue: [],
        _uid: getUid(),
    },
]);

export const useGlobalKeyValueData = (collection) => {
    if (!isFetched.includes(`${collection}-key-value`)) {
        isFetched.push(`${collection}-key-value`);

        const client = useStrapiClient();

        const {
            error,
        } = useLazyAsyncData(
            `/${collection}/get-${collection}-list`,
            () => client(`/${collection}/get-${collection}-list`, {
                method: 'GET',
            }),
            {
                server: false,
                transform: (response) => {
                    if (!response) return {};
                    return response;
                },
            },
        );

        if (error.value) {
            useSentryError(error.value);
        }
    }

    return useNuxtData(`/${collection}/get-${collection}-list`);
};
